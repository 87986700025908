import React from 'react'

function Error404() {
  return (
    <div>
        Unauthorized 404 not found
    </div>
  )
}

export default Error404 