import logo from './logo.svg';
import './App.css';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import KYC from './Archieve/KYC';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Archieve from './Archieve/Archieve';
import Accounts from './Pages/Accounts/Accounts';
import ThankyouPage from './Pages/Thankyou/Thankyou';
import PasswordReset from './Pages/Reset/password-reset';

function App() {
  return (
    <div>
      <Router>
        <Route exact path='/' component={Login}/>
        <Route exact path='/reset-password/:hash' component={PasswordReset}/>
        
        <Router exact path ='/thankyou' component={ThankyouPage} />
      </Router>
     {/* <Login/> */}
     {/* <Signup/> */}
    </div>
  );
}

export default App;
