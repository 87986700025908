import React from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { Typography , TextField , Button } from '@mui/material'
import "./KYC.css"

function KYC() {
    const [user , setUser] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('https://kycs.ariva.finance/')
            setUser(result.data)
        }
        fetchData()
        console.log(user)
    } , [])
  return (
    <div className='maindiv'>
      <h1 style={{marginTop:"5px"}}>Some Header here</h1>
      <div>
      
        
             <div  >
             <Typography   variant='h2' className='content2 fw-bold ' >YOTI UPWORK KYC</Typography>
        </div>
    </div>
      <div>
      <div className='maindiv' dangerouslySetInnerHTML={{ __html: user }} />
      </div>
         
        </div>
  )
}

export default KYC