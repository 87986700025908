import React from 'react'
import './Login.css'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Typography , TextField , Button } from '@mui/material'
import Email from '@mui/icons-material/Email'
import Password from '@mui/icons-material/Lock'
import {Link} from 'react-router-dom'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {
    const history = useHistory()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [email , setEmail] = useState('')
  
    const schema = yup.object().shape({
        
        email: yup.string().required().email("Invalid Email"),
        password: yup.string().required().min(5),
      
      })

      const { register , handleSubmit , errors} = useForm({
        resolver: yupResolver(schema)
   });
   const onSubmit = async (data) => {
    // console.log("username", data.username)
    // console.log(data)
    // console.log(errors)
    const{ email , password } = data;

    try{

        const res = await axios.post('http://localhost:5009/auth/login',{
            email : email,
            password : password
            
        })
        console.log(res)
        if(res.data.message === 'Incorrect password' || !res ){
            window.alert("Incorrect password")
        }
        else if(res.data.message === 'User not found' || !res ){
            window.alert("No user with such email")
        }
       
        else if(res.data.message === 'Something went wrong' || !res ){
            window.alert("Something went wrong")
        }
        else{
            //you need to restart the server for proxy works
            const goInn= ()=>{
                window.alert("Login Successfully");
            localStorage.setItem('email',email)
            }
            
            const check = async () => {
                const res = await axios.get('https://ariva.finance/kyca/users/'+email)
                console.log("res data here",res.data[0] && res.data[0].is_verified)
                if(res.data[0] && res.data[0].is_verified == 0){
                    history.push('/archieve/yoti-kyc-process')
                }
                else if(res.data[0] && res.data[0].is_verified == 1 && res.data[0] && res.data[0].has_account == 1)
                {
                    history.push('/thankyou')
                }
                else if(res.data[0] && res.data[0].is_verified == 1 && res.data[0] && res.data[0].has_account == 0)
                {
                    history.push('/accounts')
                }
                else
                {
                    window.alert("Something went wrong")
                
                }
            }
            check()
            goInn()
        }
    }
    catch (error) {
        console.log(error);
    }

}

const handleEmail = async () => {
    const result = await axios.post('http://localhost:5009/api/reset',
    {
        email_id: email
    })
    // console.log(result)
    console.log(result.data)
    if(result.data.message == 'Sent')
    // console.log(result.data.length)
    {
        const notify = () => toast("Email sent Successfully")  
          notify()
          handleClose()  
    }
    else{
        const notify = () => toast.error("Email not found")  
          notify()
    }
}
  return (
    <div>
       
        <div className='main_height'>
         <div className='sub_height'>
             <div className=''>
             <Typography variant='h2' className='content fw-bold' >Enter-World</Typography>
             <Typography variant='body1' className='content' >Book Your Events Now</Typography>
             </div>
          
         </div>
        </div>
    </div>
  )
}
