import React from 'react'
import axios from 'axios'
import Error404 from './Error-404'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router'
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'

function PasswordReset() {
    const history = useHistory()
    const [hash, setHash] = useState([])
    const [loading, setLoading] = useState(true)
    const [iEmail, setiEmail] = useState('')
    const [password, setpassword] = useState('')
    const [confirmpassword, setconfirmpassword] = useState('')
    const handleSubmit = async () => {
        console.log(iEmail)
        if (password === '' || confirmpassword === '') {
            alert('Please enter the password')
        }
        else if (password !== confirmpassword) {
            alert('Password and Confirm Password does not match')
        }
        else {
            await axios.put(`https://enterbkapi.herokuapp.com/auth/update/${hash[0] && hash[0].email_id}`, {
                password: password,
            })
            const notify = () => toast("Password Changed successsfully")
            notify()
        }
    }

    const location = useLocation()
    const path = location.pathname.split("/")[2]
    console.log(path)
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(`https://enterbkapi.herokuapp.com/api/reset/${path}`)
            setHash(result.data)
            console.log(result.data)
            console.log(hash[0] && hash[0].hash)
            setLoading(false)
            setiEmail(hash[0] && hash[0].user_email)
        }
        fetchData()
    }, [])
    console.log(iEmail)



    return (
        <>{loading == true ? <div>Loading...</div> : <div>{hash[0] && hash[0].hash === path ? <div>

            <div>

                <div className='main_height'>
                    <div className='sub_height'>
                        <div className=''>
                            <Typography variant='h3' className='content fw-bold' >Password Reset</Typography>


                            <div className='form_align' >
                                <Typography variant='h6' className='content fw-bold' >Password reset for {hash[0] && hash[0].email_id} </Typography>
                                <div className='icon_align' style={{ marginTop: "10px", width: '100%' }}>

                                    <input type="text" name='password' minLength={10} placeholder='Enter new password' className='focus_inactive border-0 ms-2  input_width p-2' onChange={(e) => setpassword(e.target.value)} />
                                </div>
                                <div style={{ marginTop: "10px", width: '100%' }} className='icon_align'>

                                    <input type="text" name='confirm' placeholder='Confirm new password' className='focus_inactive border-0 ms-2  input_width p-2' onChange={(e) => setconfirmpassword(e.target.value)} />
                                </div>
                                <div >
                                  { password.length >=8 && password==confirmpassword? <button className='textfield_align mt-3' style={{ marginTop: "10px", width: '100%' }} onClick={handleSubmit} >Reset password </button>:password.length <8 && confirmpassword == "" ?"Password must be 8 character":password.length>=8?"":password !== confirmpassword ?"Password not matching":""}
                                </div>
                            </div>
 
                        </div>
                        <ToastContainer />

                    </div>
                </div>
            </div>

        </div> : <Error404 />}</div>}</>
        // { path === hash ? <h1>Reset Password</h1> : <Error404/>}</>
    )
}

export default PasswordReset