import React from 'react'
export default function ThankyouPage() {
  let html = `<body>
  <div id="main">


</body>

<script language="javascript" type="text/javascript">
  var pathArray = window.location.href;
  var url = new URL(pathArray);
  var ciphertext = url.searchParams.get("Auth");
  var button = url.searchParams.get("button");

  // Replaced Text
  var dataString = ciphertext.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');


  // Back to Original Text
  ciphertext = dataString.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');

  // main.js

  // POST request using fetch()
  var status = 0;
  async function updateBook(Id) {
      if (button === "Accept") {

          status = 1;
          // document.getElementsByClassName("values")[0].innerHTML = "The Booking has been Accepted";
      }
      else if (button === "Reject") {
          // alert("Rejected");
          status = 2;
      }
      else {
          alert("Error");
      }
      // alert(status);
      try {
          await fetch("http://192.168.10.84:5009/api/booking/update/" + Id, {

              // Adding method type
              method: "PUT",

              // Adding body or contents to send
              body: JSON.stringify({
                  "status": status
              }),

              // Adding headers to the request
              headers: {
                  "Content-type": "application/json; charset=UTF-8"
              }
          })

              // Converting to JSON
              .then(response => response.json())

              // Displaying results to console
              .then(json => {
                  JSalert(json)

              });

      }
      catch (error) {
          console.log(error);
      }

  }

  function JSalert(json) {
      if (button === 'Accept' && json.message === 'UPDATED') {
          swal({
              title: "Congrats!", text: "You have successfully Accepted the booking",
              icon: "success",
              buttons: {
                  confirm: "OK",
              },
          }).then(function (isConfirm) {
              if (isConfirm) {
                  windowClose();
              }
          });
      }
      else if (button === 'Reject' && json.message === 'UPDATED') {
          swal({
              title: "Reject!", text: "You have successfully rejected the booking",
              icon: "error",
              buttons: {
                  confirm: "OK",
              },
          }).then(function (isConfirm) {
              if (isConfirm) {
                  windowClose();
              }
          });
      }
      else {
          $("#main").html('<h6>Access Denied</h6>');
          swal({
              title: "Reject!", text: "You have successfully rejected the booking",
              icon: "error",
              buttons: {
                  confirm: "OK",
              },
          }).then(function (isConfirm) {
              if (isConfirm) {
                  windowClose();
              }
          });
          // swal("Reject!", "You have successfully rejected the booking", "error");

      }
  }
 
  function windowClose() {
      window.open('', '_parent', '')
      // console.warn(window.opener)
      // if(window.opener == null){
      // $("#main").html('<h4>403w Forbidden</h4>');
      window.close();

      //     }
      //     else{

      //       window.close();
      // }
  }
</script>

<script>
  var decryptedBytes = CryptoJS.AES.decrypt(ciphertext, "My Secret Passphrase");
  var plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
  var Id = parseInt(plaintext)
  updateBook(Id)
</script>`
  return (
    
        
    
        <div className='main_height'>
        <div className="content" dangerouslySetInnerHTML={{__html: html}}></div>
    </div>
  )
}